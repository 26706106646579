import React, { useEffect, useState } from 'react'
import Img from 'gatsby-image'
import { Meter } from './Meter'
function Animation({
  images = [],
  showLoader = false,
  delayBetweenImages = 100,
  stopAfterFirstIteration = false
}) {
  const [index, setIndex] = useState(0)
  const [onNextImageStop, setOnNextImageStop] = useState(false)
  useEffect(() => {
    const id = setInterval(() => {
      setIndex(index => {
        if (onNextImageStop) {
          clearInterval(id)
          return index
        }
        const newIndex = index + 1
        if (stopAfterFirstIteration && newIndex === images.length - 1) {
          setOnNextImageStop(true)
        }
        return newIndex % images.length
      })
    }, delayBetweenImages)
    return () => clearInterval(id)
  }, [
    delayBetweenImages,
    images.length,
    onNextImageStop,
    stopAfterFirstIteration
  ])

  return (
    <>
      <Img
        className="max-h-full"
        imgStyle={{ objectFit: 'contain' }}
        fluid={images[index]?.fluid}
        alt={images[index]?.title}
      />
      {showLoader && (
        <Meter
          delay={delayBetweenImages}
          size="large"
          step={index + 1}
          numberOfSteps={images.length}
        />
      )}
    </>
  )
}

export default Animation
