import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import Reloading from '../../images/reloading.gif'
import RichText from '../../utils/richTextUtils'
import { ParseConfiguration } from '../../utils/helpers'
import Animation from './Animation'

export default function Loading({
  onlyImage = true,
  delay = 0,
  loadingImage,
  isFullScreenGif,
  minimalTopPadding = false
}) {
  const { loadingConfig, fullLoadingGifContent } = useStaticQuery(query)
  const { pageTitle, configuration, images } = loadingConfig || {}
  const subtitleHtml = loadingConfig?.content?.json
  const { assetsDictionary = {} } = ParseConfiguration(configuration)

  const _assetsDictionary = {}
  Object.keys(assetsDictionary).forEach((key) => {
    const index = assetsDictionary[key]
    if (images.length > index) {
      _assetsDictionary[key] = images[index]
    }
  })

  const image = loadingImage && _assetsDictionary[loadingImage]
  const animationImages =
    fullLoadingGifContent && fullLoadingGifContent?.references[0]?.images
  const { title = 'Loading' } = image || {}

  return (
    <React.Fragment>
      {!isFullScreenGif ? (
        <div
          className={`w-90 mx-auto${
            minimalTopPadding ? ' pt-8' : ' pt-32'
          } max-w-sm`}
        >
          {image && image.fluid ? (
            <div className="h-40">
              <Img
                className="max-h-full"
                imgStyle={{ objectFit: 'contain' }}
                fluid={image.fluid}
                alt={title}
              />
            </div>
          ) : (
            <img className="block mx-auto mb-8" src={Reloading} alt={title} />
          )}
          {!onlyImage && (
            <>
              <h1 className="text-2xl font-bold text-center md:text-4xl">
                {pageTitle}
              </h1>
              <h2 className="mt-8 text-lg text-center break-words md:text-xl text-grey-base">
                <RichText richTextDocument={subtitleHtml} />
              </h2>
            </>
          )}
        </div>
      ) : (
        <div className="relative h-full bg-brand-pale-grey">
          <div className="max-w-lg mx-auto">
            <Animation
              images={animationImages}
              showLoader={true}
              delayBetweenImages={delay / animationImages.length}
              stopAfterFirstIteration={true}
            />
          </div>
        </div>
      )}
    </React.Fragment>
  )
}

export const query = graphql`
  query PlanLoaderQuery {
    loadingConfig: contentfulEvergreenContent(
      readableId: { eq: "loading-component-setup" },
      node_locale: { eq: "en-US" }
    ) {
      id
      pageTitle
      content {
        json
      }
      images {
        id
        fluid(maxHeight: 150, quality: 100) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
        title
      }
      configuration {
        internal {
          content
        }
      }
    }
    fullLoadingGifContent: contentfulEvergreenContent(
      readableId: { eq: "loading-component-setup" },
      node_locale: { eq: "en-US" }
    ) {
      references {
        ... on ContentfulEvergreenContent {
          images {
            id
            fluid(quality: 100) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
            title
          }
        }
      }
    }
  }
`
