import { useState, useEffect, useRef } from 'react'
import { useCookies } from 'react-cookie'
import { isGoodpathEmail } from '../../common/utils/isGoodpathEmail'
const hubspot = require('@aaronhayes/react-use-hubspot-form')

// Loads a script asynchronously and returns the status of the load. Usage is:
//   const status = useScript('https://cool-script.com/whatever.js
//
// status will be `ready` when the script is fully loaded.
//
// Base here: https://usehooks.com/useScript/
//
export function useScript(src: string, options: UseScriptOptions = {}) {
  const [status, setStatus] = useState('loading')
  const [currentTry, setCurrentTry] = useState(1)
  const optionsRef = useRef(options)

  useEffect(() => {
    const options = optionsRef.current
    const maxRetries = options.maxRetries || 3

    let script: HTMLScriptElement | null = document.querySelector(
      `script[src="${src}"]`
    )

    const domStatus = script?.getAttribute('data-status')
    if (domStatus) {
      setStatus(domStatus)
      return
    }

    if (script === null) {
      script = document.createElement('script') as HTMLScriptElement
      script.src = src
      script.async = true
      script.setAttribute('data-status', 'loading')
      document.body.appendChild(script)

      const handleScriptLoad = () => {
        script?.setAttribute('data-status', 'ready')
        setStatus('ready')
        removeEventListeners()
      }

      const handleScriptError = () => {
        script?.setAttribute('data-status', 'error')
        setStatus('error')

        if (options.retryOnError && currentTry < maxRetries) {
          script?.remove()
          setTimeout(() => {
            setCurrentTry(currentTry + 1)
          }, 200)
        }
        removeEventListeners()
      }

      const removeEventListeners = () => {
        script?.removeEventListener('load', handleScriptLoad)
        script?.removeEventListener('error', handleScriptError)
      }

      script.addEventListener('load', handleScriptLoad)
      script.addEventListener('error', handleScriptError)

      const removeOnUnmount = options.removeOnUnmount

      return () => {
        if (removeOnUnmount === true) {
          script?.remove()
          removeEventListeners()
        }
      }
    } else {
      setStatus('unknown')
    }
  }, [currentTry, src])

  return status
}

interface UseScriptOptions {
  removeOnUnmount?: boolean
  retryOnError?: boolean
  maxRetries?: number
}

export const useGoodpatherCheck = (email: string) => {
  const [, setGoodpatherCookie] = useCookies(['Goodpather'])

  useEffect(() => {
    if (isGoodpathEmail(email)) {
      setGoodpatherCookie('Goodpather', 'i_am_a_GOODpather', {
        maxAge: 51840000,
        path: '/'
      })
    }
  }, [email, setGoodpatherCookie])
}

export const useExternalForm = ({
  portalId,
  formId,
  target,
  onFormSubmitted = () => {}
}: UseExternalFormProps) => {
  // @ts-ignore
  if (typeof window !== `undefined` && !(window as any).jQuery) {
    // @ts-ignore
    window.jQuery = () => {}
  }

  const props = hubspot.useHubspotForm({
    portalId,
    formId,
    target,
    onFormSubmitted
  })

  return props
}

interface UseExternalFormProps {
  portalId: string
  formId: string
  target: string
  onFormSubmitted?: () => void
}
