import React from 'react'
import Loading from '../components/Program/Loading'
import { useExternalForm } from '../utils/customHooks'
import { gaTrack } from '../utils/analytics-tracking'
import { NoIndex } from '../components/NoIndex'

const PartnerPage = ({ location }) => {
  const { loaded } = useExternalForm({
    portalId: '7420342',
    formId: 'c8981a68-c14c-4412-9774-ae605c797a13',
    target: '#partner-hubspot-form',
    onFormSubmitted: () => {
      gaTrack({
        category: 'B2B Contact',
        action: 'Submit',
        label: 'Partner'
      })
    }
  })

  return (
    <>
      <NoIndex />
      <div className="flex flex-row pt-6 pb-6 items-center justify-center text-center gp-brand">
        <div className="flex flex-col max-w-3xl px-4">
          <h1 className="text-3xl text-gp-violet">Partner with Goodpath</h1>
          <p className="pt-2 leading-relaxed text-gp-grey-100">
            Learn more about how Goodpath is helping healthcare providers,
            insurance plans, and employers improve patient satisfaction and
            quality of life.
          </p>
        </div>
      </div>
      {!loaded && <Loading />}
      <div className="flex flex-row justify-center items-center p-2">
        <div className="w-full" id="partner-hubspot-form" />
      </div>
    </>
  )
}

export default PartnerPage
