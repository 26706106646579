import React, { useState, useEffect } from 'react'
import classNames from 'classnames'

//TODO: This is too close to ProgressMeter.
//Find a way to merge them.
export function Meter({
  size = 'small',
  step = 0,
  numberOfSteps = 0,
  // Delay enables meter to load till 100
  // using a delay and the number of steps
  delay = 0,
  type = 'primary'
}) {
  const [width, setWidth] = useState(1)

  useEffect(() => {
    if (delay) {
      const id = setInterval(() => {
        setWidth(width => {
          return width === 100 ? width : width + 1
        })
      }, (delay / 100) * numberOfSteps)
      return () => clearInterval(id)
    }
  }, [delay, numberOfSteps])

  useEffect(() => {
    if (!delay) {
      setWidth((step / numberOfSteps) * 100)
    }
  }, [delay, numberOfSteps, step])

  let height
  switch (size) {
    case 'medium':
      height = '1.25rem'
      break
    case 'large':
      height = '2rem'
      break
    default:
      height = '0.625rem'
      break
  }

  const meterClass = classNames('rounded-full absolute left-0', {
    'bg-primary-base': type === 'primary',
    'bg-grey-667 text-white': type === 'disabled'
  })

  return (
    <div className="w-full relative self-center" style={{ height }}>
      <div
        className="bg-grey-555 w-full rounded-full absolute"
        style={{ height }}
      />
      <div
        className={meterClass}
        style={{ height, width: `${width}%`, minWidth: '0.7rem' }}
      />
    </div>
  )
}
